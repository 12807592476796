<template>
  <div
    v-if="loading"
    class="d-flex justify-content-center align-items-center vh-100"
  >
    <b-spinner variant="primary" label="Carregando..."></b-spinner>
  </div>
  <div v-else>
    <b-card class="mb-1">
      <b-form @submit.prevent="getData()">
        <div class="form-row">
          <div class="col-md-2">
            <div class="form-group">
              <label for="">ID Pedido Compra</label>
              <input
                type="tel"
                v-model="filters.id_purchase"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="">ID Pagamento</label>
              <input type="tel" v-model="filters.id" class="form-control" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="">Fornecedor</label>
              <input
                type="tel"
                v-model="filters.supplier"
                placeholder="Nome do fornecedor"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="">Total</label>
              <div class="d-flex items-center">
                <money
                  class="form-control mr-1"
                  v-bind="money"
                  v-model="filters.total_start"
                  type="tel"
                ></money>
                <money
                  class="form-control"
                  v-bind="money"
                  v-model="filters.total_end"
                  type="tel"
                ></money>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="">Status</label>
              <v-select
                label="title"
                :searchable="false"
                :clearable="true"
                item-text="title"
                item-value="code"
                :options="optionsStatus"
                v-model="filters.status"
                placeholder="Status"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="">Histórico</label>
              <input
                type="tel"
                v-model="filters.description"
                placeholder="Histórico"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for=""> Histórico PC </label>
              <div class="w-full" style="position: relative">
                <v-select
                  :closeOnSelect="false"
                  label="title"
                  multiple
                  :clearable="true"
                  item-text="title"
                  item-value="code"
                  :options="optionsPlans"
                  v-model="filters.plans"
                  placeholder="Histórico PC"
                  class="v-select-plans"
                >
                  <span slot="no-options">Nenhum registro encontrado</span>
                  <template #selected-option-container>
                    <div></div>
                  </template>
                </v-select>
                <div
                  v-if="filters.plans.length"
                  class="d-block w-full selected-items absolute"
                >
                  <span class="chip" v-if="filters.plans.length > 1">
                    {{ filters.plans.length > 1 ? filters.plans[0].title : "" }}
                  </span>
                  <span class="chip" v-if="filters.plans.length > 1">
                    {{ " + " + filters.plans.length + " itens" }}
                  </span>
                  <span class="chip" v-if="filters.plans.length === 1">
                    {{
                      filters.plans.length === 1 ? filters.plans[0].title : ""
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group mb-md-0">
              <label for="">Nº Documento</label>
              <input
                type="text"
                v-model="filters.document_number"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Conta Bancária</label>
              <v-select
                label="title"
                :clearable="true"
                item-text="title"
                item-value="code"
                :options="optionsBankAccounts"
                v-model="filters.bank_account"
                placeholder="Conta bancária"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-md-0">
              <label for="">Período do Pagamento</label>
              <div class="d-flex">
                <input
                  v-mask="['##/##/####']"
                  type="tel"
                  v-model="filters.completed_start"
                  placeholder="00/00/0000"
                  class="form-control mr-1"
                />
                <input
                  v-mask="['##/##/####']"
                  type="tel"
                  v-model="filters.completed_end"
                  placeholder="00/00/0000"
                  class="form-control"
                />
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group mb-md-0">
              <label for="">Período do Vencimento</label>
              <div class="d-flex">
                <input
                  v-mask="['##/##/####']"
                  type="tel"
                  v-model="filters.date_start"
                  placeholder="00/00/0000"
                  class="form-control mr-1"
                />
                <input
                  v-mask="['##/##/####']"
                  type="tel"
                  v-model="filters.date_end"
                  placeholder="00/00/0000"
                  class="form-control"
                />
              </div>
            </div>
          </div>

          <div class="col-md-2 offset-md-6">
            <button type="submit" class="btn btn-primary btn-block mt-2">
              <div v-if="submitedFilter">
                <b-spinner label="Loading..." small />
              </div>
              <div v-else>
                <i class="bi bi-search cursor-pointer cursor mr-1"></i> Buscar
              </div>
            </button>
          </div>
        </div>
      </b-form>
    </b-card>

    <div class="form-row justify-content-end mb-2">
      <div
        v-if="$can('Pagamento - Solicitar Baixa', 'Financeiro - ADM')"
        class="col-md-2"
      >
        <button
          :disabled="selectedItems.length < 1 ? true : false"
          type="button"
          class="btn btn-sm btn-success btn-block"
          @click="updateInstallmentStatus(3)"
        >
          <i
            style="font-size: 1.2rem; line-height: 1.5"
            class="bi bi-receipt-cutoff cursor-pointer cursor mr-1"
          ></i>
          Solicitar baixa
        </button>
      </div>
      <div
        class="col-md-2"
        v-if="$can('Pagamento - Criar', 'Financeiro - ADM')"
      >
        <button
          type="button"
          class="btn btn-sm btn-warning btn-block"
          @click="createPaymentConfirm()"
        >
          <i
            style="font-size: 1.2rem; line-height: 1.5"
            class="bi bi-receipt cursor-pointer cursor mr-1"
          ></i>
          Criar Pagamento
        </button>
      </div>
      <div
        v-if="$can('Pagamento - Exportar', 'Financeiro - ADM')"
        class="col-md-2"
      >
        <button
          type="button"
          class="btn btn-sm btn-primary btn-block"
          @click="downloadCSV"
        >
          <i
            style="font-size: 1.2rem; line-height: 1.5; margin-right: 5px"
            class="bi bi-download cursor-pointer cursor"
          ></i>
          Exportar
        </button>
      </div>
    </div>

    <b-card no-body>
      <b-table
        sticky-header
        :items="dataItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
        class="position-relative"
      >
        <template #head(select)>
          <b-form-checkbox
            v-model="selectAllCheckbox"
            @change="toggleSelectAll"
          />
        </template>

        <template #cell(select)="{ item }">
          <b-form-checkbox
            v-if="item.purchase_order_installment_status_name === 'A pagar'"
            style="margin-left: 13px; padding: 0px; width: 20px; margin: auto"
            :value="item.uuid"
            v-model="selectedItems"
          />
        </template>

        <template #cell(plan)="{ item }">
          <div v-if="item.plan" class="d-block text-center">
            <strong>{{ item.plan }}</strong>
            <div v-if="item.document_type_name">
              {{ item.document_type_name }}
            </div>
          </div>
          <span v-else-if="item.order_id" class="text-danger">
            <strong class="d-block">Matrícula</strong>
            Cancelada
          </span>
        </template>

        <template #cell(installment)="{ item }">
          {{ item.installment }}/{{ item.installments }}
        </template>

        <template #cell(completed)="{ item }">
          <span v-if="item.completed">
            {{ item.completed | datePT }}
          </span>
        </template>

        <template #cell(supplier)="{ item }">
          {{ item.supplier }}
        </template>

        <template #cell(purchase_order_installment_status_name)="{ item }">
          <span
            :style="`background-color: ${item.purchase_order_installment_status_background}; color: ${item.purchase_order_installment_status_color};padding: 7px 10px; border-radius: 5px; display: block; align-items: center; align-content: center;`"
          >
            {{ item.purchase_order_installment_status_name }}
          </span>
        </template>

        <template #cell(document_number)="{ item }">
          <div
            v-if="item.document_number"
            style="
              display: grid;
              align-content: center;
              justify-content: center;
              align-items: center;
            "
          >
            <div>
              {{ item.document_number }}
            </div>
            <div>{{ item.document_type_name }}</div>
          </div>
        </template>

        <template #cell(category)="{ item }">
          <span v-if="item.category">
            {{ item.category }}
          </span>
        </template>

        <template #cell(date)="{ item }">
          {{ item.date | datePT() }}
        </template>

        <template #cell(total)="{ item }">
          {{ item.total | toCurrency }}
        </template>

        <template #cell(actions)="{ item }">
          <div class="d-flex" style="column-gap: 20px">
            <router-link
              v-if="$can('Lançado - Visualizar', 'Financeiro - ADM')"
              :to="{
                name: 'orders-purchases-print',
                params: { uuid: item.purchase_order_uuid },
              }"
              class="d-flex align-items-center"
            >
              <feather-icon
                icon="EyeIcon"
                v-b-tooltip.hover.top="'Visualizar'"
                class="cursor-pointer cursor"
                size="16"
              />
            </router-link>

            <router-link
              v-if="
                $can('Pagamento - Editar', 'Financeiro - ADM') &&
                item.purchase_order_installment_status_id !== 4 &&
                item.purchase_order_installment_status_id !== 5
              "
              :to="{
                name: 'orders-purchases-payments-edit',
                params: {
                  uuid: item.purchase_order_uuid,
                  payment_uuid: item.uuid,
                },
              }"
              class="d-flex align-items-center"
            >
              <feather-icon icon="EditIcon" class="cursor-pointer" size="15" />
            </router-link>

            <feather-icon
              v-if="$can('Pagamento - Anexar Arquivo', 'Financeiro - ADM')"
              icon="UploadIcon"
              v-b-tooltip.hover.top="'Cadastrar arquivo(s)'"
              class="cursor-pointer cursor"
              size="16"
              @click="openModalUpload(item.uuid)"
            />

            <feather-icon
              icon="XCircleIcon"
              v-b-tooltip.hover.top="'Cancelar'"
              class="cursor-pointer cursor"
              v-if="
                $can('Pagamento - Cancelar', 'Financeiro - ADM') &&
                item.purchase_order_installment_status_id !== 4 &&
                item.purchase_order_installment_status_id !== 5
              "
              size="16"
              @click="cancelPayment(item.uuid)"
            />

            <feather-icon
              @click="revertInstallmentStatus(item)"
              v-if="
                $can('Pagamento - Solicitar Baixa', 'Financeiro - ADM') &&
                item.purchase_order_installment_status_id === 4
              "
              icon="RotateCcwIcon"
              v-b-tooltip.hover.top="'Reverter baixa'"
              class="cursor-pointer cursor"
              size="16"
            />
          </div>
        </template>
      </b-table>

      <CustomPaginateTable
        :rows-table="rowsTable"
        :current-page="filters.currentPage"
        :per-page="filters.perPage"
        :total-rows="totalRows"
      />
    </b-card>

    <b-modal
      id="modal-create-payment-uploads"
      modal-class="modal-create"
      title="Cadastrar arquivos"
      centered
      hide-footer
    >
      <b-form @submit.prevent="submitUploadFiles">
        <b-card-actions
          action-collapse
          class="card-purchase-order"
          no-body
          title="Arquivos cadastrados"
        >
          <ul
            class="list-group list-group-light"
            id="array-files"
            v-if="files.length"
          >
            <li
              class="list-group-item border-0 rounded-5 mb-5-px text-left px-2"
              v-for="(doc, index) in files"
              :key="index"
            >
              <div
                class="form-row"
                style="align-items: center; font-size: 0.9rem"
              >
                <div class="col-md-7 text-left">
                  <a target="_blank" :href="doc.url" title="Acessar arquivo">
                    Arquivo {{ index + 1 }}
                  </a>
                </div>
                <div class="col-md-1 text-left">
                  {{ doc.extension }}
                </div>
                <div class="col-md-3 text-center">
                  {{ doc.size | bytesToMB(2) }}
                </div>

                <div class="col-md-1 text-center">
                  <button
                    @click="submitDeleteFile(doc.code)"
                    type="button"
                    class="btn text-danger btn-sm"
                    style="padding: 5px 6px !important"
                  >
                    <i class="bi bi-trash3"></i>
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </b-card-actions>

        <div class="form-row mb-2 mt-2">
          <div class="col-md-10">
            <h4 class="mb-0">Cadastrar arquivos</h4>
            <small>Os arquivos serão anexados ao pedido de compra.</small>
          </div>
          <div class="col-md-2">
            <button
              @click="selectFiles"
              type="button"
              class="btn btn-block btn-primary"
              style="
                padding-left: 5px !important;
                padding-right: 5px !important;
              "
            >
              <i class="bi bi-plus-circle"></i>
            </button>
          </div>
        </div>

        <div class="form-row">
          <div class="col-md-12">
            <div class="form-group mb-0">
              <input
                id="files"
                type="file"
                multiple
                class="form-control-file hidden"
                @change="previewFiles"
                accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
              />

              <p
                v-if="!filesArray.length"
                class="py-3 text-center mb-0"
                style="
                  border: 1px solid rgb(222 222 222);
                  border-radius: 4px;
                  border-style: dashed;
                  font-size: 1.1rem;
                "
              >
                <i class="bi bi-file-earmark-text"></i>
                Selecionar arquivos
                <small class="text-center mt-1 d-block">
                  Selcione apenas arquivos nos formatos: <br />
                  xlsx, xls, imagem, doc, docx, ppt, pptx, txt e pdf
                </small>
              </p>

              <label class="d-block text-center mb-0">
                <ul
                  class="list-group list-group-light"
                  id="array-files"
                  v-if="filesArray.length"
                >
                  <li
                    class="list-group-item border-0 rounded-5 mb-5-px text-left px-2"
                    v-for="(doc, index) in filesArray"
                    :key="index"
                  >
                    <div
                      class="form-row"
                      style="align-items: center; font-size: 0.9rem"
                    >
                      <div class="col-md-4 text-left">
                        Arquivo {{ files.length + index + 1 }}
                      </div>
                      <div class="col-md-4">
                        {{ doc.type }}
                      </div>
                      <div class="col-md-3 text-center">
                        {{ doc.size | bytesToMB(2) }}
                      </div>
                      <div class="col-md-1 text-center">
                        <button
                          @click="deleteFile(index)"
                          type="button"
                          class="btn text-danger btn-sm"
                          style="padding: 5px 6px !important"
                        >
                          <i class="bi bi-trash3"></i>
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </label>
            </div>
          </div>
        </div>

        <div class="form-row justify-content-end modal-footer-custom">
          <div class="col-md-7 col-12">
            <button
              type="button"
              @click="$bvModal.hide('modal-create-payment-uploads')"
              class="btn btn-light float-left"
            >
              Fechar
            </button>
            <ButtonsActionsFooter
              variant="success"
              :submited="submited"
              :text="'Salvar'"
              subtext="Aguarde..."
            />
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BCardText,
  VBTooltip,
  BForm,
  BModal,
  BCardBody,
  BFormCheckbox,
  BSpinner,
} from "bootstrap-vue";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import vSelect from "vue-select";
import * as XLSX from "xlsx";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";

import { required } from "vuelidate/lib/validators";
import Ripple from "vue-ripple-directive";
import { BCardActions } from "@/@core/components/b-card-actions";
import { maskCpf } from "@/_helpers/_urlHelper";

import { Money } from "v-money";

export default {
  components: {
    BCard,
    Money,
    flatPickr,
    vSelect,
    BCardBody,
    BTable,
    BCardHeader,
    BSpinner,
    BModal,
    BCardTitle,
    BCardActions,
    BCardSubTitle,
    BFormCheckbox,
    BCardText,
    BDropdown,
    BDropdownItem,
    CustomPaginateTable,
    BDropdownDivider,
    BForm,
    ButtonsActionsFooter,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      loading: false,
      selectedItems: [],
      selectAllCheckbox: false,
      submited: false,
      textSubmit: "",
      submitedFilter: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      filters: {
        supplier: "",
        status: "",
        date_start: "",
        date_end: "",
        total_start: "",
        total_end: "",
        bank_account: "",
        observation: "",
        document_number: "",
        currentPage: 1,
        perPage: 100,
        id: "",
        id_purchase: "",
        completed_start: "",
        completed_end: "",
        plans: [],
      },
      titleModal: "",
      optionsStatus: [],
      optionsSteps: [],
      optionsInstallmentStatus: [],
      optionsPurchaseOrders: [],
      optionsDocumentTypes: [],
      optionsBankAccounts: [],
      totalRows: 0,
      rowsTable: 0,
      tableColumns: [
        { key: "select", label: "", class: "text-center" },
        { key: "id", label: "ID Pag", class: "text-center" },
        { key: "purchase_order_id", label: "ID PC", class: "text-center" },
        { key: "actions", label: "", class: "text-center" },
        { key: "document_number", label: "Documento", class: "text-center" },
        { key: "installment", label: "Parcela", class: "text-center" },
        { key: "date", label: "Vencimento", class: "text-center" },
        {
          key: "purchase_order_installment_status_name",
          label: "Situação",
          class: "text-center",
        },
        { key: "completed", label: "Baixa em", class: "text-center" },
        { key: "supplier", label: "Fornecedor", class: "text-center" },
        { key: "description", label: "Histórico", class: "text-center" },
        {
          key: "bank_account_name",
          label: "C. Bancária",
          class: "text-center",
        },
        { key: "total_final", label: "Total", class: "text-center" },
      ],
      dataItems: [],
      formModal: {
        note: "",
      },
      formModalFiles: {
        files: [],
      },
      files: [],
      item: {
        supplier: "",
        bank: "",
        plan: "",
        status: "",
        note: "",
        installment: "",
        installments: "",
        date: "",
        category: "",
        document_number: "",
        document_type: "",
        total: "",
        uuid: "",
        purchase_order_id: "",
        files: [],
      },
      optionsPlans: [],
      filesArray: [],
      displayMinSelected: 1,
    };
  },
  validations: {
    formModal: {
      document_number: {
        required,
      },
      document_type: {
        required,
      },
      bank_account: {
        required,
      },
      status: {
        required,
      },
    },
    filesArray: {
      required,
    },
  },
  watch: {
    selectedItems(newVal) {
      const items = this.dataItems.filter(
        (item) => item.purchase_order_installment_status_id === 1
      ).length;
      if (newVal.length === 0 && items === 0) {
        this.selectAllCheckbox = false;
      } else {
        this.selectAllCheckbox = newVal.length === items;
      }
    },
  },
  methods: {
    toggleSelectAll() {
      this.selectedItems = this.selectAllCheckbox
        ? this.dataItems
            .filter((item) => item.purchase_order_installment_status_id === 1)
            .map((item) => item.uuid)
        : [];
    },
    revertInstallmentStatus(item) {
      this.$store
        .dispatch("OrderPurchase/revertInstallmentStatus", {
          items: [item.uuid],
        })
        .then(() => {
          this.$bvModal.hide("select-dates-modal");
          this.notifyDefault("success");
          this.getData();
        });
    },
    toMaskCpf(cpf) {
      return maskCpf(cpf);
    },
    selectFiles() {
      const fileInput = document.getElementById("files");
      if (fileInput) {
        fileInput.click();
      } else {
        console.error("File input element not found.");
      }
    },
    deleteFile(index) {
      this.filesArray = Array.from(this.filesArray);
      this.filesArray.splice(index, 1);
    },
    previewFiles(event) {
      const input = event.target;
      if (input.files) {
        const files = input.files;

        this.filesArray = files;
      }
    },
    openModalUpload(uuid) {
      this.filesArray = [];
      this.uuid = uuid;
      this.item.files = [];

      this.$store.dispatch("OrderPurchase/getFiles", uuid).then((res) => {
        this.files = res.files;
        this.$bvModal.show("modal-create-payment-uploads");
      });
    },

    submitDeleteFile(uuid) {
      this.$store.dispatch("OrderPurchase/deleteFile", uuid).then(() => {
        this.notifyDefault("success");
        this.$bvModal.hide("modal-create-payment-uploads");
      });
    },
    submitUploadFiles() {
      this.$v.filesArray.$touch();

      if (!this.$v.filesArray.$error) {
        this.$store
          .dispatch("OrderPurchase/uploadFiles", {
            uuid: this.uuid,
            data: this.filesArray,
          })
          .then(() => {
            this.notifyDefault("success");
            this.getData();
            this.$bvModal.hide("modal-create-payment-uploads");
          });
      }
    },
    cancelPayment(uuid) {
      this.$swal({
        title: "Cancelar pagamento?",
        text: "Ao confirmar, você estaremos cancelando o pagamento.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim!",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("OrderPurchase/cancelPayment", { uuid: [uuid] })
            .then(() => {
              this.notifyDefault("success");
              this.getData();
            });
        }
      });
    },
    createPaymentConfirm() {
      this.$swal({
        title: "Criar pagamento?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$router.push({
            name: "orders-purchases-payments-create",
          });
        }
      });
    },
    updateInstallmentStatus(status) {
      this.$swal({
        title: "Solicitar baixa?",
        text: "Ao confirmar, você estará solicitando a baixa do pagamento.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim!",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("OrderPurchase/updateInstallmentStatus", {
              items: this.selectedItems,
              status,
            })
            .then(() => {
              this.notifyDefault("success");
              this.selectedItems = [];
              this.getData();
            });
        }
      });
    },
    async fetchPurchaseOrders(term) {
      if (term.length > 2) {
        this.$store
          .dispatch("OrderPurchase/forSelectOfSteps", {
            ids: [2, 3],
            term: term,
          })
          .then((res) => {
            this.optionsPurchaseOrders = res;
          });
      }
    },
    getFilterData(all = false) {
      return {
        supplier: this.filters.supplier,
        id_purchase: this.filters.id_purchase,
        total_start: this.filters.total_start,
        total_end: this.filters.total_end,
        completed_start: this.filters.completed_start,
        completed_end: this.filters.completed_end,
        document_number: this.filters.document_number,
        plans: this.filters.plans,
        date_start: this.filters.date_start,
        date_end: this.filters.date_end,
        description: this.filters.description,
        status: this.filters.status ? this.filters.status : "",
        bank_account: this.filters.bank_account
          ? this.filters.bank_account
          : "",
        id: this.filters.id,
        currentPage: all ? 0 : this.filters.currentPage,
        perPage: this.filters.perPage,
        exportData: 0,
      };
    },
    async getData() {
      this.selectedItems = [];

      this.$store
        .dispatch("OrderPurchase/allPayments", this.getFilterData())
        .then((data) => {
          if (data) {
            this.dataItems = data.data;
            this.rowsTable = data.data.length;
            this.totalRows = data.meta.total;
            this.filters.currentPage = data.meta.current_page;

            // for (let a = 0; a < this.dataItems.length; a++) {
            //   for (let n = 0; n < this.optionsSteps.length; n++) {
            //     if (this.dataItems[a].step.title === this.optionsSteps[n].title) {
            //       this.dataItems[a].step = {
            //         current: this.dataItems[a].step,
            //         next: {
            //           title: this.optionsSteps[n + 1].title,
            //           user_name: this.optionsSteps[n + 1].user_name
            //         },
            //       }
            //     }
            //   }
            // }
          }
        });
    },
    async downloadCSV() {
      try {
        const items = await this.$store.dispatch(
          "OrderPurchase/allPayments",
          this.getFilterData(true)
        );
        const toCSV = items.map((item) => {
          return {
            ID: item.id,
            "ID PC": item.purchase_order_id,
            "DOCUMENTO Nº": item.document_number,
            "DOCUMENTO TIPO": item.document_type_name,
            "CONTA BANCÁRIA": item.bank_account_name,
            "PAGO EM": item.completed,
            "HISTÓRICO PC": item.plan,
            TOTAL: Number(item.total),
            CATEGORIA: item.category,
            HISTÓRICO: item.description,
            FORNECEDOR: `${item.supplier}`,
            PARCELAS: `${item.installment}/${item.installments}`,
            DATA: moment(
              item.purchase_order_date,
              "YYYY-MM-DD HH:mm:ss"
            ).format("DD/MM/YYYY"),
            VENCIMENTO: moment(item.date, "YYYY-MM-DD HH:mm:ss").format(
              "DD/MM/YYYY"
            ),
            SITUAÇÃO: item.purchase_order_installment_status_name,
          };
        });
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(toCSV);
        XLSX.utils.book_append_sheet(wb, ws, "Tabela de Indicações");
        XLSX.writeFile(
          wb,
          `pagamento-${moment().format("DD/MM/YYYY HH:mm")}.xlsx`
        );
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    try {
      this.loading = true;
      this.getData();

      const promises = [
        this.$store.dispatch("OrderPurchase/forSelectSteps"),
        this.$store.dispatch("OrderPurchase/forSelectDocumentTypes"),
        this.$store.dispatch("BankAccount/forSelect", { term: "" }),
        this.$store.dispatch("OrderPurchase/forSelectInstallmentStatus", {
          ids: [1, 2, 3],
        }),
        this.$store.dispatch("OrderPurchase/forSelectInstallmentStatus"),
        this.$store.dispatch("OrderPurchase/forSelectPlans"),
      ];

      const results = await Promise.allSettled(promises);

      this.optionsSteps =
        results[0].status === "fulfilled" ? results[0].value : null;
      this.optionsDocumentTypes =
        results[1].status === "fulfilled" ? results[1].value : null;
      this.optionsBankAccounts =
        results[2].status === "fulfilled" ? results[2].value : null;
      this.optionsInstallmentStatus =
        results[3].status === "fulfilled" ? results[3].value : null;
      this.optionsStatus =
        results[4].status === "fulfilled" ? results[4].value : null;
      this.optionsPlans =
        results[5].status === "fulfilled" ? results[5].value : null;

      // Eventos registrados
      this.$root.$on("open:export-table", () => {
        this.$swal({
          title: "Exportar pagamentos",
          text: "O arquivo será baixado assim que confirmar.",
          iconHtml: '<i class="bi bi-download" style="font-size: 1.5rem;"></i>',
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Gerar relatório",
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-light ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.downloadCSV();
          }
        });
      });

      this.$root.$on("paginate:update", (res) => {
        this.filters.currentPage = res._currentPage;
        this.totalRows = res._totalRows;
        this.rowsTable = res._rowsTable;
        this.getData();
      });
    } catch (error) {
      console.error("Erro no mounted:", error);
    } finally {
      this.loading = false;
    }
  },
  beforeDestroy() {
    this.$root.$off("open:modal-create-order-purchase-payments");
    this.$root.$off("paginate:update");
    this.$root.$off("open:export-table");
  },
};
</script>

<style lang="scss">
.card-purchase-order {
  .card-title {
    margin-bottom: 0px !important;
    font-size: 1.1rem;
  }

  .card-header {
    padding: 10px 20px !important;
  }
}
.selected-items {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: 0px;
  left: 10px;
}

.chip {
  display: inline-flex;
  align-items: center;
  background-color: #0875d6;
  border-radius: 16px;
  color: #f1f1f1;
  padding: 5px 10px;
  margin-right: 5px;
  font-size: 0.8rem;
  margin-bottom: 5px;
}

.v-select-plans {
  .vs__clear {
    display: block !important;
  }
}
</style>
