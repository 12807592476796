var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c(
        "div",
        {
          staticClass:
            "d-flex justify-content-center align-items-center vh-100",
        },
        [
          _c("b-spinner", {
            attrs: { variant: "primary", label: "Carregando..." },
          }),
        ],
        1
      )
    : _c(
        "div",
        [
          _c(
            "b-card",
            { staticClass: "mb-1" },
            [
              _c(
                "b-form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.getData()
                    },
                  },
                },
                [
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "col-md-2" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("ID Pedido Compra"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filters.id_purchase,
                              expression: "filters.id_purchase",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "tel" },
                          domProps: { value: _vm.filters.id_purchase },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.filters,
                                "id_purchase",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-2" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("ID Pagamento"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filters.id,
                              expression: "filters.id",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "tel" },
                          domProps: { value: _vm.filters.id },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.filters, "id", $event.target.value)
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Fornecedor"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filters.supplier,
                              expression: "filters.supplier",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "tel",
                            placeholder: "Nome do fornecedor",
                          },
                          domProps: { value: _vm.filters.supplier },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.filters,
                                "supplier",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "" } }, [_vm._v("Total")]),
                        _c(
                          "div",
                          { staticClass: "d-flex items-center" },
                          [
                            _c(
                              "money",
                              _vm._b(
                                {
                                  staticClass: "form-control mr-1",
                                  attrs: { type: "tel" },
                                  model: {
                                    value: _vm.filters.total_start,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.filters, "total_start", $$v)
                                    },
                                    expression: "filters.total_start",
                                  },
                                },
                                "money",
                                _vm.money,
                                false
                              )
                            ),
                            _c(
                              "money",
                              _vm._b(
                                {
                                  staticClass: "form-control",
                                  attrs: { type: "tel" },
                                  model: {
                                    value: _vm.filters.total_end,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.filters, "total_end", $$v)
                                    },
                                    expression: "filters.total_end",
                                  },
                                },
                                "money",
                                _vm.money,
                                false
                              )
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-2" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Status"),
                          ]),
                          _c(
                            "v-select",
                            {
                              attrs: {
                                label: "title",
                                searchable: false,
                                clearable: true,
                                "item-text": "title",
                                "item-value": "code",
                                options: _vm.optionsStatus,
                                placeholder: "Status",
                              },
                              model: {
                                value: _vm.filters.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filters, "status", $$v)
                                },
                                expression: "filters.status",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "no-options" },
                                  slot: "no-options",
                                },
                                [_vm._v(" Nenhum registro encontrado ")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Histórico"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filters.description,
                              expression: "filters.description",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "tel", placeholder: "Histórico" },
                          domProps: { value: _vm.filters.description },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.filters,
                                "description",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(" Histórico PC "),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "w-full",
                            staticStyle: { position: "relative" },
                          },
                          [
                            _c(
                              "v-select",
                              {
                                staticClass: "v-select-plans",
                                attrs: {
                                  closeOnSelect: false,
                                  label: "title",
                                  multiple: "",
                                  clearable: true,
                                  "item-text": "title",
                                  "item-value": "code",
                                  options: _vm.optionsPlans,
                                  placeholder: "Histórico PC",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "selected-option-container",
                                    fn: function () {
                                      return [_c("div")]
                                    },
                                    proxy: true,
                                  },
                                ]),
                                model: {
                                  value: _vm.filters.plans,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filters, "plans", $$v)
                                  },
                                  expression: "filters.plans",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    attrs: { slot: "no-options" },
                                    slot: "no-options",
                                  },
                                  [_vm._v("Nenhum registro encontrado")]
                                ),
                              ]
                            ),
                            _vm.filters.plans.length
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-block w-full selected-items absolute",
                                  },
                                  [
                                    _vm.filters.plans.length > 1
                                      ? _c("span", { staticClass: "chip" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.filters.plans.length > 1
                                                  ? _vm.filters.plans[0].title
                                                  : ""
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.filters.plans.length > 1
                                      ? _c("span", { staticClass: "chip" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                " + " +
                                                  _vm.filters.plans.length +
                                                  " itens"
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.filters.plans.length === 1
                                      ? _c("span", { staticClass: "chip" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.filters.plans.length === 1
                                                  ? _vm.filters.plans[0].title
                                                  : ""
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-2" }, [
                      _c("div", { staticClass: "form-group mb-md-0" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Nº Documento"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filters.document_number,
                              expression: "filters.document_number",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: _vm.filters.document_number },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.filters,
                                "document_number",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Conta Bancária"),
                          ]),
                          _c(
                            "v-select",
                            {
                              attrs: {
                                label: "title",
                                clearable: true,
                                "item-text": "title",
                                "item-value": "code",
                                options: _vm.optionsBankAccounts,
                                placeholder: "Conta bancária",
                              },
                              model: {
                                value: _vm.filters.bank_account,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filters, "bank_account", $$v)
                                },
                                expression: "filters.bank_account",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "no-options" },
                                  slot: "no-options",
                                },
                                [_vm._v(" Nenhum registro encontrado ")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "form-group mb-md-0" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Período do Pagamento"),
                        ]),
                        _c("div", { staticClass: "d-flex" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: ["##/##/####"],
                                expression: "['##/##/####']",
                              },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.filters.completed_start,
                                expression: "filters.completed_start",
                              },
                            ],
                            staticClass: "form-control mr-1",
                            attrs: { type: "tel", placeholder: "00/00/0000" },
                            domProps: { value: _vm.filters.completed_start },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.filters,
                                  "completed_start",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _c("input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: ["##/##/####"],
                                expression: "['##/##/####']",
                              },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.filters.completed_end,
                                expression: "filters.completed_end",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "tel", placeholder: "00/00/0000" },
                            domProps: { value: _vm.filters.completed_end },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.filters,
                                  "completed_end",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "form-group mb-md-0" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Período do Vencimento"),
                        ]),
                        _c("div", { staticClass: "d-flex" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: ["##/##/####"],
                                expression: "['##/##/####']",
                              },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.filters.date_start,
                                expression: "filters.date_start",
                              },
                            ],
                            staticClass: "form-control mr-1",
                            attrs: { type: "tel", placeholder: "00/00/0000" },
                            domProps: { value: _vm.filters.date_start },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.filters,
                                  "date_start",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _c("input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: ["##/##/####"],
                                expression: "['##/##/####']",
                              },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.filters.date_end,
                                expression: "filters.date_end",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "tel", placeholder: "00/00/0000" },
                            domProps: { value: _vm.filters.date_end },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.filters,
                                  "date_end",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-2 offset-md-6" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary btn-block mt-2",
                          attrs: { type: "submit" },
                        },
                        [
                          _vm.submitedFilter
                            ? _c(
                                "div",
                                [
                                  _c("b-spinner", {
                                    attrs: { label: "Loading...", small: "" },
                                  }),
                                ],
                                1
                              )
                            : _c("div", [
                                _c("i", {
                                  staticClass:
                                    "bi bi-search cursor-pointer cursor mr-1",
                                }),
                                _vm._v(" Buscar "),
                              ]),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "form-row justify-content-end mb-2" }, [
            _vm.$can("Pagamento - Solicitar Baixa", "Financeiro - ADM")
              ? _c("div", { staticClass: "col-md-2" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-success btn-block",
                      attrs: {
                        disabled: _vm.selectedItems.length < 1 ? true : false,
                        type: "button",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.updateInstallmentStatus(3)
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass:
                          "bi bi-receipt-cutoff cursor-pointer cursor mr-1",
                        staticStyle: {
                          "font-size": "1.2rem",
                          "line-height": "1.5",
                        },
                      }),
                      _vm._v(" Solicitar baixa "),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm.$can("Pagamento - Criar", "Financeiro - ADM")
              ? _c("div", { staticClass: "col-md-2" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-warning btn-block",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.createPaymentConfirm()
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "bi bi-receipt cursor-pointer cursor mr-1",
                        staticStyle: {
                          "font-size": "1.2rem",
                          "line-height": "1.5",
                        },
                      }),
                      _vm._v(" Criar Pagamento "),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm.$can("Pagamento - Exportar", "Financeiro - ADM")
              ? _c("div", { staticClass: "col-md-2" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-primary btn-block",
                      attrs: { type: "button" },
                      on: { click: _vm.downloadCSV },
                    },
                    [
                      _c("i", {
                        staticClass: "bi bi-download cursor-pointer cursor",
                        staticStyle: {
                          "font-size": "1.2rem",
                          "line-height": "1.5",
                          "margin-right": "5px",
                        },
                      }),
                      _vm._v(" Exportar "),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
          _c(
            "b-card",
            { attrs: { "no-body": "" } },
            [
              _c("b-table", {
                staticClass: "position-relative",
                attrs: {
                  "sticky-header": "",
                  items: _vm.dataItems,
                  responsive: "",
                  fields: _vm.tableColumns,
                  "primary-key": "id",
                  "show-empty": "",
                  "empty-text": "Nenhum registro encontrado",
                },
                scopedSlots: _vm._u([
                  {
                    key: "head(select)",
                    fn: function () {
                      return [
                        _c("b-form-checkbox", {
                          on: { change: _vm.toggleSelectAll },
                          model: {
                            value: _vm.selectAllCheckbox,
                            callback: function ($$v) {
                              _vm.selectAllCheckbox = $$v
                            },
                            expression: "selectAllCheckbox",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "cell(select)",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        item.purchase_order_installment_status_name ===
                        "A pagar"
                          ? _c("b-form-checkbox", {
                              staticStyle: {
                                "margin-left": "13px",
                                padding: "0px",
                                width: "20px",
                                margin: "auto",
                              },
                              attrs: { value: item.uuid },
                              model: {
                                value: _vm.selectedItems,
                                callback: function ($$v) {
                                  _vm.selectedItems = $$v
                                },
                                expression: "selectedItems",
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "cell(plan)",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        item.plan
                          ? _c("div", { staticClass: "d-block text-center" }, [
                              _c("strong", [_vm._v(_vm._s(item.plan))]),
                              item.document_type_name
                                ? _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(item.document_type_name) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : item.order_id
                          ? _c("span", { staticClass: "text-danger" }, [
                              _c("strong", { staticClass: "d-block" }, [
                                _vm._v("Matrícula"),
                              ]),
                              _vm._v(" Cancelada "),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "cell(installment)",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(item.installment) +
                            "/" +
                            _vm._s(item.installments) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(completed)",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        item.completed
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("datePT")(item.completed)) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "cell(supplier)",
                    fn: function (ref) {
                      var item = ref.item
                      return [_vm._v(" " + _vm._s(item.supplier) + " ")]
                    },
                  },
                  {
                    key: "cell(purchase_order_installment_status_name)",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "span",
                          {
                            style:
                              "background-color: " +
                              item.purchase_order_installment_status_background +
                              "; color: " +
                              item.purchase_order_installment_status_color +
                              ";padding: 7px 10px; border-radius: 5px; display: block; align-items: center; align-content: center;",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.purchase_order_installment_status_name
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(document_number)",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        item.document_number
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "grid",
                                  "align-content": "center",
                                  "justify-content": "center",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    " " + _vm._s(item.document_number) + " "
                                  ),
                                ]),
                                _c("div", [
                                  _vm._v(_vm._s(item.document_type_name)),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "cell(category)",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        item.category
                          ? _c("span", [
                              _vm._v(" " + _vm._s(item.category) + " "),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "cell(date)",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _vm._v(" " + _vm._s(_vm._f("datePT")(item.date)) + " "),
                      ]
                    },
                  },
                  {
                    key: "cell(total)",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " + _vm._s(_vm._f("toCurrency")(item.total)) + " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(actions)",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex",
                            staticStyle: { "column-gap": "20px" },
                          },
                          [
                            _vm.$can("Lançado - Visualizar", "Financeiro - ADM")
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass: "d-flex align-items-center",
                                    attrs: {
                                      to: {
                                        name: "orders-purchases-print",
                                        params: {
                                          uuid: item.purchase_order_uuid,
                                        },
                                      },
                                    },
                                  },
                                  [
                                    _c("feather-icon", {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover.top",
                                          value: "Visualizar",
                                          expression: "'Visualizar'",
                                          modifiers: { hover: true, top: true },
                                        },
                                      ],
                                      staticClass: "cursor-pointer cursor",
                                      attrs: { icon: "EyeIcon", size: "16" },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.$can(
                              "Pagamento - Editar",
                              "Financeiro - ADM"
                            ) &&
                            item.purchase_order_installment_status_id !== 4 &&
                            item.purchase_order_installment_status_id !== 5
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass: "d-flex align-items-center",
                                    attrs: {
                                      to: {
                                        name: "orders-purchases-payments-edit",
                                        params: {
                                          uuid: item.purchase_order_uuid,
                                          payment_uuid: item.uuid,
                                        },
                                      },
                                    },
                                  },
                                  [
                                    _c("feather-icon", {
                                      staticClass: "cursor-pointer",
                                      attrs: { icon: "EditIcon", size: "15" },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.$can(
                              "Pagamento - Anexar Arquivo",
                              "Financeiro - ADM"
                            )
                              ? _c("feather-icon", {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.top",
                                      value: "Cadastrar arquivo(s)",
                                      expression: "'Cadastrar arquivo(s)'",
                                      modifiers: { hover: true, top: true },
                                    },
                                  ],
                                  staticClass: "cursor-pointer cursor",
                                  attrs: { icon: "UploadIcon", size: "16" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openModalUpload(item.uuid)
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.$can(
                              "Pagamento - Cancelar",
                              "Financeiro - ADM"
                            ) &&
                            item.purchase_order_installment_status_id !== 4 &&
                            item.purchase_order_installment_status_id !== 5
                              ? _c("feather-icon", {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.top",
                                      value: "Cancelar",
                                      expression: "'Cancelar'",
                                      modifiers: { hover: true, top: true },
                                    },
                                  ],
                                  staticClass: "cursor-pointer cursor",
                                  attrs: { icon: "XCircleIcon", size: "16" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.cancelPayment(item.uuid)
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.$can(
                              "Pagamento - Solicitar Baixa",
                              "Financeiro - ADM"
                            ) && item.purchase_order_installment_status_id === 4
                              ? _c("feather-icon", {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.top",
                                      value: "Reverter baixa",
                                      expression: "'Reverter baixa'",
                                      modifiers: { hover: true, top: true },
                                    },
                                  ],
                                  staticClass: "cursor-pointer cursor",
                                  attrs: { icon: "RotateCcwIcon", size: "16" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.revertInstallmentStatus(item)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("CustomPaginateTable", {
                attrs: {
                  "rows-table": _vm.rowsTable,
                  "current-page": _vm.filters.currentPage,
                  "per-page": _vm.filters.perPage,
                  "total-rows": _vm.totalRows,
                },
              }),
            ],
            1
          ),
          _c(
            "b-modal",
            {
              attrs: {
                id: "modal-create-payment-uploads",
                "modal-class": "modal-create",
                title: "Cadastrar arquivos",
                centered: "",
                "hide-footer": "",
              },
            },
            [
              _c(
                "b-form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submitUploadFiles.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "b-card-actions",
                    {
                      staticClass: "card-purchase-order",
                      attrs: {
                        "action-collapse": "",
                        "no-body": "",
                        title: "Arquivos cadastrados",
                      },
                    },
                    [
                      _vm.files.length
                        ? _c(
                            "ul",
                            {
                              staticClass: "list-group list-group-light",
                              attrs: { id: "array-files" },
                            },
                            _vm._l(_vm.files, function (doc, index) {
                              return _c(
                                "li",
                                {
                                  key: index,
                                  staticClass:
                                    "list-group-item border-0 rounded-5 mb-5-px text-left px-2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "form-row",
                                      staticStyle: {
                                        "align-items": "center",
                                        "font-size": "0.9rem",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "col-md-7 text-left" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                target: "_blank",
                                                href: doc.url,
                                                title: "Acessar arquivo",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " Arquivo " +
                                                  _vm._s(index + 1) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-1 text-left" },
                                        [
                                          _vm._v(
                                            " " + _vm._s(doc.extension) + " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-3 text-center" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("bytesToMB")(doc.size, 2)
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-1 text-center" },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn text-danger btn-sm",
                                              staticStyle: {
                                                padding: "5px 6px !important",
                                              },
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.submitDeleteFile(
                                                    doc.code
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "bi bi-trash3",
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c("div", { staticClass: "form-row mb-2 mt-2" }, [
                    _c("div", { staticClass: "col-md-10" }, [
                      _c("h4", { staticClass: "mb-0" }, [
                        _vm._v("Cadastrar arquivos"),
                      ]),
                      _c("small", [
                        _vm._v(
                          "Os arquivos serão anexados ao pedido de compra."
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-2" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-block btn-primary",
                          staticStyle: {
                            "padding-left": "5px !important",
                            "padding-right": "5px !important",
                          },
                          attrs: { type: "button" },
                          on: { click: _vm.selectFiles },
                        },
                        [_c("i", { staticClass: "bi bi-plus-circle" })]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("div", { staticClass: "form-group mb-0" }, [
                        _c("input", {
                          staticClass: "form-control-file hidden",
                          attrs: {
                            id: "files",
                            type: "file",
                            multiple: "",
                            accept:
                              ".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf",
                          },
                          on: { change: _vm.previewFiles },
                        }),
                        !_vm.filesArray.length
                          ? _c(
                              "p",
                              {
                                staticClass: "py-3 text-center mb-0",
                                staticStyle: {
                                  border: "1px solid rgb(222 222 222)",
                                  "border-radius": "4px",
                                  "border-style": "dashed",
                                  "font-size": "1.1rem",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "bi bi-file-earmark-text",
                                }),
                                _vm._v(" Selecionar arquivos "),
                                _c(
                                  "small",
                                  { staticClass: "text-center mt-1 d-block" },
                                  [
                                    _vm._v(
                                      " Selcione apenas arquivos nos formatos: "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " xlsx, xls, imagem, doc, docx, ppt, pptx, txt e pdf "
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "label",
                          { staticClass: "d-block text-center mb-0" },
                          [
                            _vm.filesArray.length
                              ? _c(
                                  "ul",
                                  {
                                    staticClass: "list-group list-group-light",
                                    attrs: { id: "array-files" },
                                  },
                                  _vm._l(_vm.filesArray, function (doc, index) {
                                    return _c(
                                      "li",
                                      {
                                        key: index,
                                        staticClass:
                                          "list-group-item border-0 rounded-5 mb-5-px text-left px-2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row",
                                            staticStyle: {
                                              "align-items": "center",
                                              "font-size": "0.9rem",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-md-4 text-left",
                                              },
                                              [
                                                _vm._v(
                                                  " Arquivo " +
                                                    _vm._s(
                                                      _vm.files.length +
                                                        index +
                                                        1
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-md-4" },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(doc.type) + " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-md-3 text-center",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("bytesToMB")(
                                                        doc.size,
                                                        2
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-md-1 text-center",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn text-danger btn-sm",
                                                    staticStyle: {
                                                      padding:
                                                        "5px 6px !important",
                                                    },
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.deleteFile(
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "bi bi-trash3",
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "form-row justify-content-end modal-footer-custom",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "col-md-7 col-12" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-light float-left",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.$bvModal.hide(
                                    "modal-create-payment-uploads"
                                  )
                                },
                              },
                            },
                            [_vm._v(" Fechar ")]
                          ),
                          _c("ButtonsActionsFooter", {
                            attrs: {
                              variant: "success",
                              submited: _vm.submited,
                              text: "Salvar",
                              subtext: "Aguarde...",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }